
































































import mixins from 'vue-typed-mixins'
import {
  lessonStore,
  snackbarStore,
} from '@/store'
import LessonCreateEditFields from '@/components/Lesson/CreateEditFields.vue'
import {
  Lesson,
} from '@/types/lesson'
import {
  Id,
} from '@/types/base'
import {
  newLesson,
} from '@/util/lesson'
import {
  form,
} from '@/mixins'
import omit from 'lodash.omit'

export default mixins(form)
  .extend({
    components: {
      LessonCreateEditFields,
    },
    data: () => ({
      loadingSave: false,
      loadingDelete: false,
      editLesson: newLesson(),
    }),
    computed: {
      loadingGet (): boolean {
        return lessonStore.isLoadingGet
      },
      hasChanged (): boolean {
        const oldLesson = this.$copy(this.lesson)
        const editLesson = omit(oldLesson, [
          `id`,
        ])
        return !this.$isEqual<Lesson>(this.editLesson, editLesson)
      },
      lesson (): (Lesson & Id) {
        return lessonStore.dataById(this.$route.params.id) ?? {
          id: ``,
          ...newLesson(),
        }
      },
    },
    watch: {
      lesson: {
        immediate: true,
        handler (lesson: (Lesson & Id)) {
          this.reset(lesson)
        },
      },
    },
    async created () {
      await lessonStore.get(this.$route.params.id)
    },
    methods: {
      async save () {
        if (!this.validateForm()) return
        const editLesson: (Lesson & Id) = {
          id: this.$route.params.id,
          ...this.$copy(this.editLesson),
        }
        try {
          this.loadingSave = true
          await lessonStore.update(editLesson)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Lesson successfully updated`,
          })
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error updating lesson`,
          })
        }
        this.loadingSave = false
      },
      async deleteLesson () {
        if (!confirm(`Are you sure you want to delete "${this.lesson.name}"?`)) return
        try {
          this.loadingDelete = true
          await lessonStore.delete(this.$route.params.id)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Lesson successfully deleted`,
          })
          this.$router.go(-1)
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error deleting lesson`,
          })
        }
        this.loadingDelete = false
      },
      async reset (lesson: (Lesson & Id)) {
        const oldLesson = this.$copy(lesson)
        const editLesson = omit(oldLesson, [
          `id`,
        ])
        this.editLesson = editLesson
      },
    },
  })
